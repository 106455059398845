<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'NewsDetail',
});

const classNameRef = ref(['']);
const { $mopI18n, $urls, $storyblokLivePreview } = useNuxtApp();
const { getCmsStory, cmsStoryModelRef, loadingRef, trackPageView } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { handleHeadForCmsPage } = useMopSeo();
const route = useRoute();
const router = useRouter();

initTransition(classNameRef, loadingRef);

onMounted(async () => {
  await getCmsStory(
    `/news/detail/${(route.params.path as string).replace('theme-company/', '').replace('theme-company', '')}`,
  );
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    router.replace($mopI18n.localePath($urls.NEWS_PAGE));
    return;
  }
  trackPageView('News');
});

const cmsContentState = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }
  const publishedDate: string = cmsStoryModelRef.value.getAttribute('publishedDate');
  const category: string = cmsStoryModelRef.value.getAttribute('category');
  const title: string = cmsStoryModelRef.value.getAttribute('title');
  const subTitle: string = cmsStoryModelRef.value.getAttribute('subTitle');
  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.news.name'),
      url: $mopI18n.localePath($urls.NEWS_PAGE),
    },
    {
      name: title,
      url: route.path,
    },
  ];
  return {
    publishedDate,
    category,
    title,
    subTitle,
    breadcrumbs,
  };
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }
  return handleHeadForCmsPage(cmsStoryModelRef.value);
});
useHead(seoHeadRef);
</script>

<template>
  <div :class="['news-item-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <MopBreadcrumb v-if="cmsContentState.breadcrumbs" :breadcrumbs="cmsContentState.breadcrumbs" />
      <div class="news-item-page__head">
        <div v-if="cmsContentState.category" class="news-item-page__category">
          {{
            $mopI18n.te(`locale.news.categories.${cmsContentState.category}`)
              ? $mopI18n.t(`locale.news.categories.${cmsContentState.category}`)
              : cmsContentState.category
          }}
        </div>
        <div v-if="cmsContentState.publishedDate" class="news-item-page__published-date">
          {{ $mopI18n.formatDate(cmsContentState.publishedDate) }}
        </div>
      </div>
      <h1 class="news-item-page__headline">
        {{ cmsContentState.title }}
      </h1>

      <h2 class="news-item-page__sub-headline">
        {{ cmsContentState.subTitle }}
      </h2>

      <MopCmsContentElementsSlot id="news-item-page" :elements="cmsStoryModelRef.getContentElements()" no-padding />

      <NuxtLink
        class="button button--primary news-item-page__back-button"
        :to="$mopI18n.localePath($urls.NEWS_PAGE)"
        no-prefetch
      >
        {{ $mopI18n.t('locale.news.go_back') }}
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news-item-page {
  padding: $space10 $global-padding 0;

  @include apply-upto(small) {
    padding-top: 0;
  }
}

.news-item-page__head {
  @include text-style(strong);

  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}

.news-item-page__headline {
  @include text-style(headline, 0);

  margin: $space20 0;
  text-transform: uppercase;
}

.news-item-page__sub-headline {
  @include text-style(strong);

  margin: $space15 0;
  text-transform: uppercase;
}

.news-item-page__back-button {
  margin: $space10 0;
  text-transform: uppercase;
}
</style>
